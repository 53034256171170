import {
  Stack,
  Title,
  Group,
  Paper,
  Button,
  Anchor,
  Breadcrumbs,
  Badge,
  ActionIcon,
} from '@mantine/core';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { useQuery } from '@tanstack/react-query';
import { API } from 'services';
import { Link, useNavigate } from 'react-router-dom';
import { IconExternalLink, IconPlus } from '@tabler/icons-react';
import { DataTable } from 'components/DataTable';
import { useState } from 'react';
import { DataTableSortStatus } from 'mantine-datatable';
import DashboardIcon from 'components/icons/DashboardIcon';

export function UserManagementPage() {
  const { md } = useBreakpoints();
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({
    columnAccessor: 'name',
    direction: 'asc',
  });

  const handleSortStatusChange = (status: DataTableSortStatus) => {
    setPage(1);
    setSortStatus(status);
  };

  const { data, isFetching } = useQuery(
    ['user-management/users', sortStatus.columnAccessor, sortStatus.direction, page],
    () => {
      return API.getUserList({
        page,
        sort: sortStatus.columnAccessor,
        direction: sortStatus.direction,
      })
        .then((res) => res.data)
        .then((res) => {
          return res.data;
        });
    },
    {
      keepPreviousData: true,
    }
  );

  const items = [
    {
      title: (
        <ActionIcon>
          <DashboardIcon />
        </ActionIcon>
      ),
      href: '/dashboard',
    },
    { title: 'User Management', href: '/user-management' },
  ].map((item, index) => (
    <Anchor
      key={index}
      onClick={() => {
        navigate(item.href);
      }}
    >
      {item.title}
    </Anchor>
  ));

  return (
    <Stack px="lg">
      <Breadcrumbs>{items}</Breadcrumbs>
      <Paper p="sm">
        <Group position="left" align="center" mb="lg">
          <Title order={1}>User Management</Title>
          <Link to={'/user-management/create'}>
            <Button leftIcon={<IconPlus />}>New User</Button>
          </Link>
        </Group>

        <DataTable
          fetching={isFetching}
          page={page}
          onPageChange={setPage}
          totalRecords={data?.total}
          recordsPerPage={data?.page_size || 25}
          sortStatus={sortStatus}
          onSortStatusChange={handleSortStatusChange}
          records={data ? data.records : []}
          // define columns
          columns={[
            {
              accessor: 'partner_user_name',
              sortable: true,
              render: (record: any, index) => {
                return (
                  <Link to={`/user/${record.id}`}>
                    {record.partner_user_name} <IconExternalLink size={'0.8rem'} />
                    {!record.status ? `(Inactive)` : ''}
                    {record.role === 'admin' ? (
                      <Badge ml={4} size="xs" variant="outline">
                        ⚙️ Admin
                      </Badge>
                    ) : (
                      ''
                    )}
                  </Link>
                );
              },
            },
            { accessor: 'partner_user_mobile', sortable: true },
            { accessor: 'partner_user_email', sortable: true },
            { accessor: 'referral_code', sortable: true },
            {
              accessor: 'status',
              sortable: true,
              render: (record: any) => {
                return record.status ? 'Active' : 'Inactive';
              },
            },
            {
              accessor: 'action',
              render: (record) => {
                return (
                  <Link to={`/user/${record.id}`}>
                    <Button size="xs" variant="outline">
                      View
                    </Button>
                  </Link>
                );
              },
            },
          ]}
        />
      </Paper>
    </Stack>
  );
}
