import { AppShell, Box, Text } from '@mantine/core';
import { NavbarContext, NavbarSimple } from './Navbar';
import { Outlet, useNavigate } from 'react-router-dom';
import { Header } from './Header';
import { useEffect, useState } from 'react';
import { useLoginUser } from 'redux/selectors/useLoginUser';
import { fetchUserInfoThunk } from 'redux/reducers/auth';
import { useDispatch } from 'react-redux';
import { useBreakpoints } from 'hooks/useBreakpoints';

export const Layout = () => {
  const dispatch = useDispatch();
  const [navbarOpened, setNavbarOpened] = useState(false);
  const { loginUser } = useLoginUser();
  const navigate = useNavigate();
  const { sm, md } = useBreakpoints(false);

  useEffect(() => {
    if (!loginUser) {
      navigate('/login');
    } else {
      dispatch(fetchUserInfoThunk() as any);
    }
  }, []);

  // redirect
  if (!loginUser) {
    return null;
  }

  return (
    <NavbarContext.Provider
      value={{
        opened: navbarOpened,
        toggle: () => setNavbarOpened((o) => !o),
      }}
    >
      <AppShell
        sx={() => ({
          main: {
            transition: 'all 0.1s ease-in-out',
          },
        })}
        navbar={<NavbarSimple />}
        styles={(theme) => ({
          main: {
            width: 0, // workaround: need a default width
            flexGrow: 1,
            flexShrink: 1,
            backgroundColor: '#F5FAFF',
          },
        })}
      >
        <Header />
        <Outlet />

        {/* Footer */}
        <Box px="lg" mt={100}>
          <span>
            @{new Date().getFullYear()}{' '}
            <Text display={'inline'} color="brand" weight={800}>
              Firstcom Academy
            </Text>
          </span>
        </Box>
      </AppShell>
    </NavbarContext.Provider>
  );
};
