import { createContext, useContext, useState } from 'react';
import {
  createStyles,
  Navbar,
  Group,
  getStylesRef,
  rem,
  Box,
  Text,
  Title,
  Button,
  Stack,
  Transition,
  ScrollArea,
  Center,
  Loader,
  Paper,
} from '@mantine/core';
import { IconLink } from '@tabler/icons-react';
// import { ReactComponent as FCALogo } from 'assets/FCA.svg';
import FCALogo from 'assets/FCA.png';
import { useClickOutside, useClipboard } from '@mantine/hooks';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ScreenSvg } from 'assets/dashboard/icon_screen.svg';
import { ReactComponent as DashboardSvg } from 'assets/navbar/icon_dash.svg';
import { ReactComponent as UserManagementSvg } from 'assets/navbar/icon_user m.svg';
import { ReactComponent as UpdatePasswordSvg } from 'assets/navbar/icon_pass.svg';
import { ReactComponent as FaqSvg } from 'assets/navbar/icon_faq.svg';
import { ReactComponent as NotificationSvg } from 'assets/navbar/icon_notify.svg';
import { ReactComponent as ProfileSvg } from 'assets/navbar/icon_profile.svg';
import { ReactComponent as LogoutSvg } from 'assets/navbar/icon_logout.svg';
import { useDispatch } from 'react-redux';
import { clearLoginUser } from 'redux/reducers/auth';
import { API } from 'services';
import { useMutation } from '@tanstack/react-query';
import { modals } from '@mantine/modals';
import { useLoginUser } from 'redux/selectors/useLoginUser';
import { notifications } from 'notifications';
import { ReferralLinks } from 'components/ReferralLinks';

const useStyles = createStyles((theme) => ({
  header: {
    paddingBottom: theme.spacing.md,
  },

  footer: {
    paddingTop: theme.spacing.md,
    marginTop: theme.spacing.md,
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
    }`,
  },

  link: {
    ...theme.fn.focusStyles(),
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    cursor: 'pointer',
    fontSize: theme.fontSizes.sm,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[7],
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    borderRadius: theme.radius.sm,
    fontWeight: 500,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,

      [`& .${getStylesRef('icon')}`]: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      },
    },
  },

  linkIcon: {
    ref: getStylesRef('icon'),
    color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[6],
    marginRight: theme.spacing.sm,
    filter: 'grayscale(1)',
  },

  linkActive: {
    '&, &:hover': {
      backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
      color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
      borderRight: `3px solid #5f7839`,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      [`& .${getStylesRef('icon')}`]: {
        filter: 'none',
        color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
      },
    },
  },
}));

const data = [
  { link: '/dashboard', label: 'Dashboard', icon: DashboardSvg },
  { link: '/user-management', label: 'User Management', icon: UserManagementSvg, admin_only: true },
  // { link: '', label: 'Update Password', icon: UpdatePasswordSvg },
  // { link: '', label: 'Notification', icon: NotificationSvg },
  // { link: '', label: 'FAQ', icon: FaqSvg },
  { link: '/profile', label: 'Profile', icon: ProfileSvg },
];

export function NavbarSimple() {
  const { classes, cx } = useStyles();
  const [active, setActive] = useState('Dashboard');
  const navbar = useNavbarContext();
  const { sm } = useBreakpoints(true);
  const ref = useClickOutside(() => (!sm && navbar.opened ? navbar.toggle() : null));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginUser } = useLoginUser();
  const clipboard = useClipboard();

  const links = data.map((item) => {
    if (item.admin_only && loginUser.role !== 'admin') return null;

    return (
      <a
        key={item.label}
        className={cx(classes.link, { [classes.linkActive]: item.label === active })}
        href={item.link}
        onClick={(event) => {
          event.preventDefault();
          setActive(item.label);
          navigate(item.link);
        }}
      >
        <item.icon className={classes.linkIcon} name="test" />
        <span>{item.label}</span>
      </a>
    );
  });

  const handleLogout = useMutation(() => {
    return new Promise((resolve) => {
      modals.openConfirmModal({
        zIndex: 1001,
        title: 'Logout',
        color: 'red',
        labels: {
          cancel: 'No',
          confirm: 'Yes',
        },
        confirmProps: { color: 'red' },
        children: 'Are you sure you want to logout?',
        onConfirm: () => {
          modals.open({
            id: 'loging out',
            title: 'Logging out...',
          });

          resolve(
            API.logout().finally(() => {
              modals.closeAll();
              navigate('/login', { replace: true });
              setTimeout(() => {
                dispatch(clearLoginUser());
              }, 1000);
            })
          );
        },
        onCancel: () => {
          resolve(null);
        },
        onClose: () => {
          resolve(null);
        },
      });
    });
  });

  return (
    <Transition
      mounted={navbar.opened || sm}
      transition={'slide-right'}
      duration={100}
      timingFunction="ease"
    >
      {(styles) => {
        return (
          <Navbar
            ref={ref}
            style={{ bottom: 'unset', ...styles }}
            sx={(theme) => ({
              width: 250,
              height: '100vh',
              position: 'sticky',
              [theme.fn.smallerThan('sm')]: {
                zIndex: 1000,
                width: '80vw',
                position: 'fixed',
              },
            })}
          >
            <ScrollArea h="100vh">
              <Navbar.Section grow>
                <Group className={classes.header} position="center">
                  {sm ? (
                    <Box w={'70%'} h="auto" my={20}>
                      <img src={FCALogo} alt="FCA Logo" />
                    </Box>
                  ) : null}
                </Group>
                {links}
              </Navbar.Section>

              <Navbar.Section mt={100}>
                <Stack m="md" p="md" bg="teal.1" style={{ borderRadius: 5 }}>
                  <Center>
                    <ScreenSvg />
                  </Center>

                  {loginUser.partner_type === 'Trainer' ? (
                    <Stack>
                      <Title order={4} size={'0.8rem'} mx={20} style={{ textAlign: 'center' }}>
                        <Text weight={'900'} display={'inline'} color="brand">
                          10%{' '}
                        </Text>
                        Commission for Alumni
                      </Title>
                      <Title order={4} size={'0.8rem'} mx={20} style={{ textAlign: 'center' }}>
                        <Text weight={'900'} display={'inline'} color="blue">
                          20%{' '}
                        </Text>
                        Commission for Non Alumni
                      </Title>
                    </Stack>
                  ) : (
                    <Title order={4} size={'0.8rem'} mx={20} style={{ textAlign: 'center' }}>
                      Refer & Earn, Share, Get Paid, repeat !
                    </Title>
                  )}

                  <ReferralLinks
                    referral_link={loginUser.referral_link}
                    referral_link_with_contact_us={loginUser.referral_link_with_contact_us}
                  />
                </Stack>
              </Navbar.Section>

              <Navbar.Section className={classes.footer}>
                <a href="#" className={classes.link} onClick={() => handleLogout.mutateAsync()}>
                  <Box mr="0.75rem" className={classes.linkIcon}>
                    <LogoutSvg />
                  </Box>
                  <span>Logout</span>
                  {handleLogout.isLoading && <Loader ml={'md'} size={'xs'} />}
                </a>

                {import.meta.env.DEV ? (
                  <Text size={'xs'} px="lg">
                    {'API_URL: ' + import.meta.env.VITE_API_URL}
                  </Text>
                ) : null}
              </Navbar.Section>
            </ScrollArea>
          </Navbar>
        );
      }}
    </Transition>
  );
}

export const NavbarContext = createContext({
  opened: true,
  toggle: () => {
    return;
  },
});

export const useNavbarContext = () => {
  const context = useContext(NavbarContext);
  if (!context) {
    throw new Error('useNavbarContext must be used within NavbarProvider');
  }
  return context;
};
