import {
  Stack,
  Title,
  Group,
  Paper,
  Button,
  Anchor,
  Breadcrumbs,
  TextInput,
  Text,
  ActionIcon,
  Code,
  CopyButton,
  Select,
} from '@mantine/core';
import { useMutation, useQuery } from '@tanstack/react-query';
import { API } from 'services';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  IconClipboardCheck,
  IconDeviceFloppy,
  IconGift,
  IconLink,
  IconMail,
  IconPhone,
  IconRefresh,
  IconUser,
} from '@tabler/icons-react';
import { z } from 'zod';
import { useForm, zodResolver } from '@mantine/form';
import { getErrorMessage } from 'services/getErrorMessage';
import { notifications } from 'notifications';
import { modals } from '@mantine/modals';
import { useClipboard } from '@mantine/hooks';
import { GradientButton } from 'components/GradientButton';
import DashboardIcon from 'components/icons/DashboardIcon';
import { ReferralLinks } from 'components/ReferralLinks';

const schema = z.object({
  name: z
    .string()
    .max(255, { message: 'Name is too long' })
    .nonempty({ message: 'Name is required' }),
  email: z.string().email({ message: 'Invalid email' }),
  mobile: z
    .string()
    .max(255, { message: 'Mobile is too long' })
    .nonempty({ message: 'Mobile is required' }),
  status: z.string().nonempty({ message: 'Status is required' }),
});

type FormValue = z.infer<typeof schema>;

export function UserDetailPage() {
  const { user_id } = useParams();
  const { copy } = useClipboard();
  const location = useLocation();

  const query = useQuery(['user-management/user-detail', user_id], () => {
    if (!user_id) return Promise.reject(new Error('User ID is required'));
    return API.getUserDetail(user_id)
      .then((res) => res.data.data)
      .then((user) => {
        form.setValues({
          name: user.name || '',
          mobile: user?.mobile || '',
          email: user?.email || '',
          status: user?.status?.toString() || '',
        });
        return user;
      });
  });

  const navigate = useNavigate();
  const form = useForm<FormValue>({
    validate: zodResolver(schema),
  });

  const items = [
    {
      title: (
        <ActionIcon>
          <DashboardIcon />
        </ActionIcon>
      ),
      href: '/dashboard',
    },
    { title: 'User Management', href: '/user-management' },
    { title: 'User', href: location.pathname },
  ].map((item, index) => (
    <Anchor
      key={index}
      onClick={() => {
        navigate(item.href);
      }}
    >
      {item.title}
    </Anchor>
  ));

  const handleSubmit = useMutation(
    (values: FormValue) => {
      return API.updateUser({
        user_id: user_id!,
        status: values.status,
        name: values.name,
        email: values.email,
        mobile: values.mobile,
      });
    },
    {
      onSuccess: (response) => {
        const responseBody = response.data;
        let isConfirm = false;
        modals.openConfirmModal({
          title: '🎉 Updated',
          children: responseBody.message,
          labels: {
            cancel: 'Back',
            confirm: (
              <>
                <span>
                  <IconRefresh />
                </span>
                <span>Refresh</span>
              </>
            ),
          },
          onConfirm: () => {
            isConfirm = true;
            // reload page
            navigate(0);
          },
          onClose: () => {
            if (!isConfirm) {
              navigate(`/user-management`, { replace: true });
            }
          },
        });
      },
      onError: (error) => {
        notifications.error({
          message: getErrorMessage(error),
        });
      },
    }
  );

  if (query.isFetching) {
    return <div>Loading...</div>;
  }

  if (query.isError) {
    return <div>Error...</div>;
  }

  if (!query.data) {
    return <div>No data...</div>;
  }

  const user = query.data;

  return (
    <Stack px="lg">
      <Breadcrumbs>{items}</Breadcrumbs>
      <Paper p="sm">
        <Group position="left" align="center" mb="lg">
          <Title order={1}>{user.name}</Title>
        </Group>

        <form>
          <Stack maw={400} w="100%">
            <TextInput
              w="100%"
              icon={<IconUser />}
              label="Name"
              placeholder="Name"
              {...form.getInputProps('name')}
            />
            <TextInput
              w="100%"
              icon={<IconMail />}
              label="Email"
              placeholder="Email"
              {...form.getInputProps('email')}
            />
            <TextInput
              w="100%"
              icon={<IconPhone />}
              label="Mobile"
              placeholder="Mobile"
              {...form.getInputProps('mobile')}
            />
            <TextInput
              variant="filled"
              contentEditable={false}
              w="100%"
              icon={<IconGift />}
              label="Referral Code"
              placeholder="Referral Code"
              value={user.referral_code}
            />

            <Select
              label={`Status`}
              data={[
                { value: '1', label: '🟩 Active' },
                { value: '0', label: '🔴 Inactive' },
              ]}
              {...form.getInputProps('status')}
            />

            <ReferralLinks
              referral_link={user.referral_link}
              referral_link_with_contact_us={user.referral_link_with_contact_us}
            />

            {form.isDirty() && (
              <Group mt={'lg'} position="right">
                <Button
                  loading={handleSubmit.isLoading}
                  leftIcon={<IconDeviceFloppy />}
                  onClick={() => {
                    if (!form.validate().hasErrors) {
                      handleSubmit.mutateAsync(form.values);
                    }
                  }}
                >
                  Save
                </Button>
              </Group>
            )}
          </Stack>
        </form>
      </Paper>
    </Stack>
  );
}
